var resources = {
    en: {
        translation: require('../Content/Locale/translation-en.json')
    }
    
};

if ($.cdh.global.environment.toLowerCase() != "prod") {
    var frenchResource = {
        fr: {
            translation: require('../Content/Locale/translation-fr.json')
        }
    };
    var spanishResource = {
        es: {
            translation: require('../Content/Locale/translation-es.json')
        }
    }

    resources = {
        ...resources,
        ...frenchResource,
        ...spanishResource
    }
}


(function ($) {
    var isI18NextInitialized = false;
    const i18nextObj = i18next
        .use(i18nextBrowserLanguageDetector)
        .init({
            debug: true,
            resources,
            fallbackLng: ['en'],
            defaultNS: 'translation',
            detection: {
                order: ['localStorage']
            },
            missingKeyHandler: function (lngs, namespace, key) {
                console.log(`Key not found.\nLanguage:${lngs}\nNamespace:${namespace}\nKey:${key}`);
            }
      

    }).then((t) => {
        isI18NextInitialized = true;
    }).catch((err) => {
        if (err) return console.log('something went wrong loading', err);
    });

    var languageConstant = {

        changeLanguage: function (lng) {
            i18next.changeLanguage(lng);
        },

        getLanguage: function () {
            const availableLanguages = [
                { name: 'English', id: 'en' },
                { name: 'Français', id: 'fr' },
                { name: 'Español', id: 'es' },
            ]
            const language = availableLanguages.find(lang => lang.id === i18next.language);
            return language ? language : availableLanguages[0];
        },
        getResource: function (key, targetLng, options = '') {
            return i18next.getResource(targetLng, i18next.options.defaultNS, key, options);
        },
        getConstant: function (key, param) {
            return (param == null || param == 'undefined') ? i18next.t(key) : i18next.t(key, param);
        },
    }
    //Event binding
    i18next.on("initialized", function (options) {
        // $(".options-language").val(i18next.resolvedLanguage);
        //updating the language value in the layout       
        console.log('i18next Initialized!')
    });

    //Event binding for loading
    i18next.on('loaded', function (loaded) {
        console.log('Is resource loaded: ' + loaded);
    });

    $.extend(true, $, {
        cdh: {
            languageConstant: languageConstant
        }
    });
})(jQuery);

